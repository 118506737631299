
































import { defineComponent, computed } from '@vue/composition-api';
import DataSchema from '../@types/data-schema';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object as () => DataSchema,
    },
  },
  setup(props) {
    const { dataSet, title } = props.data;
    return {
      dataSet,
      title,
    };
  },
});
